import { OnInit, Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { HttpClient } from '@angular/common/http';
export interface ConfirmModel {
    url: string;
    json: any;
}
@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html'
})


export class LoadingComponent extends SimpleModalComponent<ConfirmModel, null> implements OnInit {
    url: string;
    json: any;
    cargando: boolean;
    mensaje: string;

    constructor(private http: HttpClient) {
        super();
    }

    ngOnInit(){
        this.cargando = true;

        this.http.post(this.url, this.json, { responseType: 'text' }).subscribe( res => {
            this.cargando = false;
            this.mensaje = 'Gracias por la información, nos pondremos en contacto contigo.';
        }, error => {
            this.cargando = false;
            this.mensaje = 'El teléfono o el correo proporcionado ya han sido utilizados.';
        });
        
    }
    
}