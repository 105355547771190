import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { PresentationComponent } from './presentation/presentation.component';
import { FormAvisoPrivacidadComponent } from './components/form-avisoprivacidad/form-avisoprivacidad.component';
import { ContactanosComponent } from './components/contactanos/contactanos.component';
import { DatosComponent } from './components/datos/datos.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component'; 
import { ProyectosComponent } from './components/proyectos/proyectos.component'; 


const routes: Routes =[
    { path: '',         component: PresentationComponent },
    { path: 'contactanos',   component: ContactanosComponent },
    { path: 'avisoprivacidad',   component: FormAvisoPrivacidadComponent },
    { path: 'datos',   component: DatosComponent },
    { path: 'nosotros', component: NosotrosComponent},
    { path: 'proyectos', component: ProyectosComponent},
    { path: '**', component: PresentationComponent}
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes,{
          useHash: false
        })
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
