import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { PresentationModule } from './presentation/presentation.module';
import { ContactanosModule } from './components/contactanos/contactanos.module';
import { CarouselComponent } from './components/carousel/carousel.component';
import { DatosComponent } from './components/datos/datos.component';
import { NosotrosComponent } from './components/nosotros/nosotros.component';
import { ProyectosComponent } from './components/proyectos/proyectos.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        DatosComponent,
        NosotrosComponent,
        ProyectosComponent
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        PresentationModule,
        ContactanosModule,
        NgbCarouselModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
