import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { ContactanosComponent } from './contactanos.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '../loading/loading.module';
import { SimpleModalModule } from 'ngx-simple-modal';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        NgxCaptchaModule,
        ReactiveFormsModule,
        LoadingModule,
        NgbModule,
        SimpleModalModule.forRoot({container: "modal-contacto"})
    ],
    declarations: [ ContactanosComponent ],
    exports:[ ContactanosComponent ],
    providers: []
})
export class ContactanosModule { }