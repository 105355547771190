/// <reference types="googlemaps" />
import { Component, OnInit, OnDestroy, HostListener, ElementRef, ViewChild,Renderer2 } from '@angular/core';
import { Blog } from 'app/entities/blog';
import { SeccionBlogTexto } from 'app/entities/seccion-blog-texto';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

declare const $: any;

@Component({
    selector: 'app-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit, OnDestroy {

    @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
    //para el mapa
    styles: any[] = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];

    //para el formulario de contacto
    @ViewChild('contacto') divContacto:ElementRef;
    @ViewChild('areas') divAreas:ElementRef;

    //para los blogs
    blog1: Blog = new Blog()
    blog2: Blog = new Blog()
    //para el footer
    fecha: Date = new Date();

    constructor(
        private renderer: Renderer2,
        public el: ElementRef) {
    }

    //ni idea una vez mas
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const componentPosition = document.getElementsByClassName('add-animation');
        const scrollPosition = window.pageYOffset;
        
        for(var i = 0; i < componentPosition.length; i++) {
            var rec = componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
            if ( scrollPosition + window.innerHeight >= rec ) {
                componentPosition[i].classList.add('animated');
            } else if ( scrollPosition + window.innerHeight * 0.8 < rec ) {
                componentPosition[i].classList.remove('animated');
            }
        }
    }

    ngOnInit() {
        $('.owl-carousel').owlCarousel({
            loop:true,
            autoplay:true,
            autoplayTimeout:1500,
            responsive:{
                0:{
                    items:3
                },
                767:{
                    items:5
                },
                1400:{
                    items:7
                }
            }
        })
        this.initMap();

        var body = document.getElementsByTagName('body')[0];
        body.classList.add('presentation-page');
        body.classList.add('loading');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');

        this.blog1.titulo = "Titulo del blog 1"
        this.blog1.tipo = "Empresarial"
        this.blog1.fecha = new Date()
        this.blog1.imagen = "./assets/img/sections/bruno-abatti.jpg"
        let seccion1 = new SeccionBlogTexto()
        seccion1.tipoSeccion = "texto"
        seccion1.texto = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras commodo nisl nec nibh posuere vehicula. Duis in lacinia lectus. Maecenas sem orci, varius quis pulvinar eu, faucibus at magna. Vestibulum consequat, est non hendrerit semper, libero justo sollicitudin mauris, ut lobortis felis nulla in felis. Sed ultrices urna at enim luctus, at venenatis leo elementum."
        this.blog1.secciones.push(seccion1)

        this.blog2.titulo = "Titulo mas largo del blog 2"
        this.blog2.tipo = "de Tecnología"
        this.blog2.fecha = new Date()
        this.blog2.imagen = "./assets/img/sections/federico-beccari.jpg"
        let seccion2 = new SeccionBlogTexto()
        seccion2.tipoSeccion = "texto"
        seccion2.texto = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras commodo nisl nec nibh posuere vehicula. Duis in lacinia lectus. Maecenas sem orci, varius quis pulvinar eu, faucibus at magna. Vestibulum consequat, est non hendrerit semper, libero justo sollicitudin mauris, ut lobortis felis nulla in felis. Sed ultrices urna at enim luctus, at venenatis leo elementum."
        this.blog2.secciones.push(seccion2)

    }

    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('presentation-page');
        body.classList.remove('loading');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }

    //para el mapa de google maps
    initMap(): void {
        const center = { lat: 27.492733407164014, lng: -109.94440233744257 };
        const marker = { lat: 27.49271241939168, lng: -109.94247148914796 };
        const map = new google.maps.Map (
            document.getElementById("googlemap") as HTMLElement, {
                zoom: 18,
                center: center,
                styles: [{
                    featureType: "poi",
                    stylers: [
                        { visibility: "off" }
                    ]   
                }]
            }
        );
        new google.maps.Marker({
            position: marker,
            map,
            title: "Zitheonsoft",
            icon: {
                url: '../../assets/images/landing/marker-zitheonsoft.png',
                scaledSize : new google.maps.Size(40, 64),
            }
        });
    }

    scrollContacto() {
        this.divContacto.nativeElement.scrollIntoView({ behavior: 'smooth', alignToTop: true });
    }

    images = [
        { name: 'banner_inicio_uno_2.webp', others:"1", caption: 'Solucionar tus necesidades empresariales por medio de software innovador es nuestra pasión.'},
        { name: 'banner_inicio_dos_2.webp', others:"2", caption: 'Ofrecemos servicios de desarrollo de software dinámico e innovadores que contribuyan a cada organización en el cumplimiento eficaz de sus objetivos mediante un trabajo confiable, oportuno y preciso.'},
        { name: 'banner_inicio_tres_1.webp', others:"3", caption: 'Desarrollo, diseño y calidad de aplicaciones web con las últimas tecnologías como Spring y React Native, nos adaptamos a las tecnologías que sean requeridas.'},
        { name: 'banner_inicio_cuatro_2.webp', others:"4", caption: 'Nos involucramos estrechamente con nuestros clientes creando lazos de confianza, al grado de superar sus expectativas.'},
    ]

    //

    isMouseDown = false;
    startX: number;

    onMouseDown(event: MouseEvent) {
    this.isMouseDown = true;
    this.startX = event.clientX;
    }

    onMouseMove(event: MouseEvent) {
    if (this.isMouseDown) {
        const deltaX = event.clientX - this.startX;
        if (deltaX > 50) {
        // Desplazamiento hacia la derecha, avanzar al siguiente slide
        this.carousel.prev();
        } else if (deltaX < -50) {
        // Desplazamiento hacia la izquierda, retroceder al slide anterior
        this.carousel.next();
        }
    }
    }

    onMouseUp() {
    this.isMouseDown = false;
    }

    

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const screenWidth = window.innerWidth;

    // Elimina todas las clases CSS del elemento <div>
    this.renderer.removeClass(this.el.nativeElement, 'mi-div');

    // Agrega una clase CSS en función de la resolución actual
    if (screenWidth <= 768) {
      this.renderer.addClass(this.el.nativeElement, 'mi-div-small');
    } else if (screenWidth <= 1200) {
      this.renderer.addClass(this.el.nativeElement, 'mi-div-medium');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'mi-div-large');
    }
  }
  
}