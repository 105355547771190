import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalService } from 'ngx-simple-modal';
import { LoadingComponent } from '../loading/loading.component';

@Component({
    selector: 'app-contactanos',
    templateUrl: './contactanos.component.html',
    styleUrls:['./contactanos.component.scss']
})

export class ContactanosComponent implements OnInit {

    //para el mapa
    styles: any[] = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
    
    //para el footer
    fecha: Date = new Date();

    //para el formulario de contacto
    contact = { 
        name: "",
        email: "",
        phone: "",
        company: "",
        industria: "",
        servicios: [""],
        message: "",
        acceptPolicy: false
    };
    hacerScrollContacto: boolean = false
    aFormGroup: FormGroup;
    captchaKey: string;

    //para los errores del formulario de contacto
    contactErrors = {
        noName: false,
        noLastname: false,
        noPhone: false,
        noEmail: false,
        noCompany: false,
        noIndustria: false,
        noServicio: false,
        incorrectEmail: false,
        incorrectPhone: false,
        noMessage: false,
        noAcceptPolicy: false
    };

    checks: Array<String> = [
        'Aplicación web',
        'Aplicación móvil',
        'Aplicación de escritorio',
        'CRM',
        'ERP',
        'Gestión de mantenimiento de equipos',
        'Digitalización de procesos',
        'Otro'
      ];
    
    constructor(public  _modal:NgbModal,
        private formBuilder: FormBuilder,
        private simpleModalService: SimpleModalService) {
    }

    ngOnInit() {
        //lo que pone la barra azul, tambien esta en el on destroy
        // var navbar = document.getElementsByTagName('nav')[0];
        // navbar.classList.remove('navbar-transparent');
        // navbar.classList.add('bg-primary');

        this.aFormGroup = this.formBuilder.group({
            recaptcha: ['', Validators.required],
            nombre: new FormControl(),
            industria: new FormControl(),
            servicios: new FormArray([]),
            correo: new FormControl(),
            telefono: new FormControl(),
            compania: new FormControl(),
            mensaje: new FormControl(),
            politica: new FormControl()
        });
        this.captchaKey = '6Le2jz0aAAAAABFeCqEq8B0rwXmFtyLvR1Bph-wt';

        this.initMap();
    }

    ngOnDestroy(){
        // var navbar = document.getElementsByTagName('nav')[0];
        // navbar.classList.remove('bg-primary');
        // navbar.classList.add('navbar-transparent');
    }

    //para el mapa de google maps
    initMap(): void {
        const center = { lat: 27.492733407164014, lng: -109.94440233744257 };
        const marker = { lat: 27.49271241939168, lng: -109.94247148914796 };
        const map = new google.maps.Map (
            document.getElementById("googlemap2") as HTMLElement, {
                zoom: 16,
                center: center,
                styles: [{
                    featureType: "poi",
                    stylers: [
                        { visibility: "off" }
                    ]   
                }]
            }
        );
        new google.maps.Marker({
            position: marker,
            map,
            title: "Zitheonsoft",
            icon: {
                url: '../../assets/images/landing/marker-zitheonsoft.png',
                scaledSize : new google.maps.Size(40, 64),
            }
        });
    }
    
    //para validar el formulario de contacto
    nameChange() {
        this.contactErrors.noName = !this.contact.name;
    }
    
    emailChange() {
        this.checkEmail();
    }
    
    phoneChange() {
        this.checkPhone();
    }

    serviciosChange() {
        this.checkServicios();
    }

    companyChange() {
        this.contactErrors.noCompany = !this.contact.company;
    }

    industriaChange() {
        this.contactErrors.noIndustria = !this.contact.industria;
    }
    
    messageChange() {
        this.contactErrors.noMessage = !this.contact.message;
    }
    
    acceptPolicyChange() {
        this.contactErrors.noAcceptPolicy = !this.contact.acceptPolicy;
    }
    
    checkPhone(){
        if (this.contact.phone) {
            if(this.contact.phone.length == 12 || this.contact.phone.length == 13) {
                this.contactErrors.noPhone = false;
                this.contactErrors.incorrectPhone = !Number(this.contact.phone);
            }
            else {
                this.contactErrors.noPhone = false;
                this.contactErrors.incorrectPhone = true;
            }
        } else {
            this.contactErrors.noPhone = true;
            this.contactErrors.incorrectPhone = false;
        }
    }
    
    checkEmail() {
        if (this.contact.email) {
    
            let re = /\S+@\S+\.\S+/;
    
            if (!re.test(this.contact.email)) {
                this.contactErrors.incorrectEmail = true;
            } else {
                this.contactErrors.incorrectEmail = false;
            }
    
            this.contactErrors.noEmail = false;
        } else {
            this.contactErrors.noEmail = true;
        }
    }
    
    //para enviar el correo
    sendContactEmail() {
        this.contactErrors.noName = !this.contact.name;
        this.contactErrors.noCompany = !this.contact.company;
        this.contactErrors.noIndustria = !this.contact.industria;
        this.contactErrors.noMessage = !this.contact.message;
        this.contactErrors.noAcceptPolicy = !this.contact.acceptPolicy;
        this.checkEmail();
        this.checkPhone();
        this.checkServicios();
        if (this.contactErrors.noName || this.contactErrors.noLastname || this.contactErrors.noMessage || this.contactErrors.incorrectPhone ||
            this.contactErrors.noEmail || this.contactErrors.incorrectEmail || this.contactErrors.noAcceptPolicy || this.contactErrors.noCompany ||
            this.contactErrors.noPhone || this.contactErrors.noServicio || this.contactErrors.noIndustria) { //this.contactErrors.noAcceptPolicy
            // console.log("Hubo un error en el mensaje!");
            return;
        }    

        //enviar el correo
        const url = 'https://us-central1-calcium-vector-297620.cloudfunctions.net/guardarcontacto';

        const data = {
            // apellido: this.contact.lastname,
            compania: this.contact.company,
            correo: this.contact.email,
            industria: this.contact.industria,
            // fechaHoraCreacion: '',
            mensaje: this.contact.message,
            nombre: this.contact.name,
            servicios: this.contact.servicios.toString(),
            telefono: this.contact.phone
        };
    
        const json = JSON.stringify(data);

        this.simpleModalService.addModal(LoadingComponent, {url: url, json: json});
    
    }

    // Checa los servicios, marca error cuando no hay ninguno y si existen, los agrega al contacto
    checkServicios() {
        const formArray: FormArray = this.aFormGroup.get('servicios') as FormArray;
        if (formArray.controls.length === 0) {
            this.contactErrors.noServicio = true;
        } else {
            this.contact.servicios = [];
            this.contactErrors.noServicio = false;
            formArray.controls.forEach((ctrl: FormControl) => {
                this.contact.servicios.push(ctrl.value);
            });
        }
    }

    // Agrega y elimina los checks a la lista
    onCheckChange(event) {
        const formArray: FormArray = this.aFormGroup.get('servicios') as FormArray;

        /* Selected */
        if(event.target.checked){
            // Add a new control in the arrayForm
            formArray.push(new FormControl(event.target.value));
        }
        /* unselected */
        else {
            // find the unselected element
            let i: number = 0;
      
            formArray.controls.forEach((ctrl: FormControl) => {
                if(ctrl.value == event.target.value) {
                    // Remove the unselected element from the arrayForm
                    formArray.removeAt(i);
                    return;
                }
                i++;
            });
        }
    }
}