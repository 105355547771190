import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormAvisoPrivacidadComponent } from './form-avisoprivacidad.component';
import { CommonModule } from '@angular/common';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SimpleModalModule } from 'ngx-simple-modal';
import { LoadingModule } from '../loading/loading.module';
import { RouterModule } from '@angular/router';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxCaptchaModule,
        ReactiveFormsModule,
        LoadingModule,
        RouterModule
    ],
    declarations: [
        FormAvisoPrivacidadComponent
    ],
    exports: [
        FormAvisoPrivacidadComponent
    ]
})
export class FormAvisoPrivacidadModule { }