import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { defaultSimpleModalOptions, SimpleModalModule } from 'ngx-simple-modal';
import { LoadingComponent } from './loading.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        SimpleModalModule.forRoot({container: 'modal-contacto'}, {...defaultSimpleModalOptions, ...{
            closeOnEscape: false,
            closeOnClickOutside: false,
            wrapperDefaultClasses: 'o-modal o-modal--fade',
            wrapperClass: 'o-modal--fade-in',
            animationDuration: 300,
            autoFocus: true
        }})
    ],
    declarations: [
        LoadingComponent
    ],
    exports: [
        LoadingComponent
    ]
})
export class LoadingModule { }