import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormContactoComponent } from './form-contacto.component';
import { CommonModule } from '@angular/common';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SimpleModalModule } from 'ngx-simple-modal';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxCaptchaModule,
        ReactiveFormsModule,
        LoadingModule,
        SimpleModalModule.forRoot({container: "modal-contacto"})
    ],
    declarations: [
        FormContactoComponent
    ],
    exports: [
        FormContactoComponent
    ]
})
export class FormContactoModule { }