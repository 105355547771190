/// <reference types="googlemaps" />
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalService } from 'ngx-simple-modal';
import { LoadingComponent } from '../loading/loading.component';

@Component({
    selector: 'app-form-contacto',
    templateUrl: './form-contacto.component.html',
    styleUrls: ['./form-contacto.component.scss']
})

export class FormContactoComponent implements OnInit {
    
    //para el formulario de contacto
    contact = { 
        name: "",
        lastname: "",
        email: "",
        phone: "",
        company: "",
        iam: "",
        message: "",
        acceptPolicy: false
    };
    hacerScrollContacto: boolean = false
    aFormGroup: FormGroup;
    captchaKey: string;

    //para los errores del formulario de contacto
    contactErrors = {
        noName: false,
        noLastname: false,
        noEmail: false,
        incorrectEmail: false,
        incorrectPhone: false,
        noMessage: false,
        noAcceptPolicy: false
    };

    constructor(
        public  _modal:NgbModal,
        private formBuilder: FormBuilder,
        private simpleModalService: SimpleModalService) {
    }

    ngOnInit() {
        this.aFormGroup = this.formBuilder.group({
            recaptcha: ['', Validators.required],
            nombre: new FormControl(),
            apellido: new FormControl(),
            correo: new FormControl(),
            telefono: new FormControl(),
            compania: new FormControl(),
            mensaje: new FormControl(),
            politica: new FormControl()
        });
        this.captchaKey = '6Le2jz0aAAAAABFeCqEq8B0rwXmFtyLvR1Bph-wt';
    }

    //para validar el formulario de contacto
    nameChange() {
        this.contactErrors.noName = !this.contact.name
    }
    lastnameChange() {
        this.contactErrors.noLastname = !this.contact.lastname
    }
    emailChange() {
        this.checkEmail()
    }
    phoneChange() {
        this.checkPhone()
    }
    messageChange() {
        this.contactErrors.noMessage = !this.contact.message
    }
    acceptPolicyChange() {
        this.contactErrors.noAcceptPolicy = !this.contact.acceptPolicy
    }
    checkPhone(){
        if (this.contact.phone) {
            if(this.contact.phone.length == 12 || this.contact.phone.length == 13) {
                this.contactErrors.incorrectPhone = !Number(this.contact.phone)
            }
            else {
                this.contactErrors.incorrectPhone = true
            }
        } else {
            this.contactErrors.incorrectPhone = false
        }
    }
    checkEmail() {
        if (this.contact.email){

            let re = /\S+@\S+\.\S+/;

            if (!re.test(this.contact.email)) {
                this.contactErrors.incorrectEmail = true;
            } else {
                this.contactErrors.incorrectEmail = false;
            }

            this.contactErrors.noEmail = false
        } else {
            this.contactErrors.noEmail = true
        }
    }

    //para enviar el correo
    sendContactEmail() {
        this.contactErrors.noName = !this.contact.name
        this.contactErrors.noLastname = !this.contact.lastname
        this.contactErrors.noMessage = !this.contact.message
        this.contactErrors.noAcceptPolicy = !this.contact.acceptPolicy
        console.log("Politica: " + this.contactErrors.noAcceptPolicy)
        this.checkEmail()
        this.checkPhone()
        if (this.contactErrors.noName || this.contactErrors.noLastname || this.contactErrors.noMessage || this.contactErrors.incorrectPhone ||
            this.contactErrors.noEmail || this.contactErrors.incorrectEmail || this.contactErrors.noAcceptPolicy ) { //this.contactErrors.noAcceptPolicy
            // console.log("Hubo un error en el mensaje!")
            return
        }

        //enviar el correo
        const url = 'https://us-central1-calcium-vector-297620.cloudfunctions.net/guardarcontacto';

        const data = {
            apellido: this.contact.lastname,
            compania: this.contact.company,
            correo: this.contact.email,
            // fechaHoraCreacion: '',
            mensaje: this.contact.message,
            nombre: this.contact.name,
            telefono: this.contact.phone
        };
    
        const json = JSON.stringify(data);
        
        this.simpleModalService.addModal(LoadingComponent, {url: url, json: json});

    }

}
