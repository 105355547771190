import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.css']
})
export class ProyectosComponent implements OnInit {

  fecha: Date = new Date();

  constructor() { }

  ngOnInit(): void {
  }

  images = [
    { others:"1"},
    { others:"2"},
    { others:"3"},
    { others:"4"},
    { others:"5"},
    { others:"6"},
    { others:"7"},
    { others:"8"},
    { others:"9"},
    { others:"10"},
    { others:"11"},
]
}
