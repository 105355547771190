import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { PresentationComponent } from './presentation.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FormContactoModule } from '../components/form-contacto/form-contacto.module';
import { FormAvisoPrivacidadModule } from '../components/form-avisoprivacidad/form-avisoprivacidad.module';
import { ContactanosModule } from 'app/components/contactanos/contactanos.module';
import { CarouselComponent } from 'app/components/carousel/carousel.component';
import { AppModule } from 'app/app.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule,
        NgbModule,
        FormContactoModule,
        ContactanosModule,
        FormAvisoPrivacidadModule,
        NgbCarouselModule
    ],
    declarations: [ PresentationComponent, CarouselComponent ],
    exports:[ PresentationComponent ],
    providers: []
})
export class PresentationModule { }
