import { SeccionBlog } from "./seccion-blog"

export class Blog {
    public titulo: string
    public subtitulo: string
    public tipo: string //Empresarial, de Tecnología, Tutorial
    public autor: string
    public fecha: Date
    public imagen: string
    //public blogsRelacionados: Array<Blog>
    public informacionDescargable: boolean
    public secciones: Array<SeccionBlog>

    constructor() {
        this.secciones = new Array<SeccionBlog>()
    }
}
