import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SimpleModalService } from 'ngx-simple-modal';
import { LoadingComponent } from '../loading/loading.component';

@Component({
    selector: 'app-form-avisoprivacidad',
    templateUrl: './form-avisoprivacidad.component.html',
    styleUrls: ['./form-avisoprivacidad.component.scss']
})

export class FormAvisoPrivacidadComponent implements OnInit {

    //Para el footer
    fecha: Date = new Date();

    constructor(
        public  _modal:NgbModal,
        private formBuilder: FormBuilder,
        private simpleModalService: SimpleModalService) {
    }

    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('contact-page');
        //lo que pone la barra azul, tambien esta en el on destroy
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
        navbar.classList.add('bg-primary');
    }

    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('contact-page');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('bg-primary');
        navbar.classList.add('navbar-transparent');
    }
}